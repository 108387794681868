@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;700&family=Open+Sans:wght@300;400;700&display=swap');

:root {
  --color-primary: #145DA0;
  --color-primary-light: #2E8BC0;
  --color-primary-dark: #0C2D48;

  --color-secondary-light: #ffb900;
  --color-secondary-dark: #ff7730;

  --color-tertiary-light: #2998ff;
  --color-tertiary-dark: #5643fa;

  --color-grey-light-1: #f7f7f7;
  --color-grey-light-2: #eee;

  --color-grey-dark: #343a40;
  --color-grey-dark-2: #999;
  --color-grey-dark-3: #333;

  --color-white: #fff;
  --color-black: #000;

  /* Font Size */
  --default-font-size: 1.6rem;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 62.5%; /* 1rem = 10px, 10px/16px (browser default) */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-grey-dark);
}

@media only screen and (max-width: 59.357em) {
  html {
      font-size: 56.25%;
  }
}

h1 {
  font-family: 'Bitter';
  font-size: 6rem;
  font-weight: 300;
  text-transform: uppercase;
}

h2 {
  font-family: 'Bitter';
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: var(--default-font-size);
}
