.section:not(:last-of-type) {
	margin-bottom: 1.5rem;
}

.title {
	font-size: 2rem;
}

.list {
	font-size: 16px;
	margin-left: 1.5rem;
}

.list li {
	margin-top: 5px;
}
