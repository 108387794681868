.container {
	display: flex;
	margin-bottom: 5rem;
}

.image {
	height: 4.8rem;
	width: 4.8rem;
	margin-right: 1rem;
}

.title {
	font-size: 2rem;
}

.company {
	font-size: 1.8rem;
}

.list {
	margin: 1rem 0 0 1.5rem;
    font-size: 16px;
}

.listItem {
	margin-bottom: 1rem;
	line-height: 1.6;
}
